<template>
  <div class="cart">
    <b-container class="py-5">
      <b-row>
        <b-col cols="12" class="text-center mb-3" v-if="!cartProducts.length">
          <b-button variant="primary" class="px-5 py-1" to="/products">
            <img
              src="/media/svg/shopping-cart.svg"
              alt="shopping cart"
              class="mr-1"
              width="20"
            />
            {{ t("cart").shopNow }}
          </b-button>
        </b-col>

        <b-col cols="12">
          <ek-table
            :items="cartProducts"
            :columns="cols"
            class="cart-table rounded"
            no_delete
            no_select
          >
            <template #header>
              <div class="table-header d-flex justify-content-between w-100">
                <h5 class="text-light">{{ t("cart").cart }}</h5>
                <h5 class="text-light">
                  {{ t("cart").cartCount }} :
                  {{ cartCount }}
                </h5>
              </div>
            </template>
            <template slot="headers" slot-scope="{ props }">
              <span>
                {{ t("cart")[props.column.field] }}
              </span>
            </template>
            <template slot="items.name" slot-scope="{ props }">
              <b-link :to="'/products/' + props.row.productId">
                <div class="d-flex h-100 align-items-center justify-content-center">
                  <img
                    width="60"
                    height="60"
                    class="rounded-circle"
                    :src="$store.getters['app/domainHost'] + '/' + props.row.image"
                    style="object-fit: cover"
                  />
                  <div
                    class="ml-1 d-flex flex-column justify-content-center align-items-start"
                  >
                    <h4>{{ props.row.name }}</h4>
                    <div class="text-nowrap text-white">
                      <template v-if="props.row.attribute.length">
                        <small
                          class="text-light"
                          v-for="(attribute, index) in props.row.attribute"
                          :key="index"
                        >
                          {{ attribute.name }}
                          {{ index != props.row.attribute.length - 1 ? "-" : "" }}
                        </small>
                      </template>
                      <small v-else>
                        {{ t("cart").noAddons }}
                      </small>
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template slot="items.price" slot-scope="{ props }">
              {{ getProductPrice(props.row) }}
              {{ !currType ? t("sp") : t("usd") }}
            </template>
            <template slot="items.quantity" slot-scope="{ props }">
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  class="quantity"
                  variant="custom-blue-sky"
                  @click="setNewQuantity(props.row, ++props.row.quantity, 1)"
                >
                  <unicon name="plus" height="15" fill="#fff"></unicon>
                </b-button>
                <h4 class="mx-2 m-0">
                  {{ props.row.quantity }}
                </h4>
                <b-button
                  class="quantity"
                  variant="custom-blue-sky"
                  @click="setNewQuantity(props.row, --props.row.quantity, -1)"
                >
                  <unicon name="minus" height="15" fill="#fff"></unicon>
                </b-button>
              </div>
            </template>
            <template slot="items.totalPrice" slot-scope="{ props }">
              <span v-if="!currType">
                {{
                  props.row.attribute.reduce((pre, curr) => {
                    return props.row.quantity * curr.price + pre;
                  }, props.row.price * props.row.quantity) | numFormat
                }}
              </span>

              <span v-else>
                {{
                  props.row.attribute.reduce((pre, curr) => {
                    return props.row.quantity * curr.dollarPrice + pre;
                  }, props.row.dollarPrice * props.row.quantity) | numFormat
                }}
              </span>

              {{ !currType ? t("sp") : t("usd") }}
            </template>
            <template slot="items.delete" slot-scope="{ props }">
              <b-button
                variant="flat-secondary btn-icon"
                @click="removeCartItem(props.row.id)"
              >
                <unicon fill="#fff" name="times"></unicon>
              </b-button>
            </template>
          </ek-table>
        </b-col>
        <b-col cols="12">
          <div class="metal-border rounded p-25">
            <div class="custom-bg p-2 rounded d-flex justify-content-between">
              <h4 class="text-light m-0">
                {{ t("cart").cartTotalPrice }}
              </h4>
              <h4 class="text-light m-0">
                {{ totalPrice | numFormat }}
                {{ !currType ? t("sp") : t("usd") }}
              </h4>
            </div>
          </div>
        </b-col>
        <b-col cols="12" class="mt-2">
          <div class="metal-border rounded p-25">
            <div class="custom-bg p-25 rounded">
              <ek-input-textarea
                fieldClass="bg-transparent text-light border-0"
                :placeholder="t('cart').note"
                name="note"
                row="3"
                v-model="setOrderDto.note"
              ></ek-input-textarea>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="w-100 mt-5 d-flex align-items-center justify-content-center">
            <b-button
              :disabled="!cartProducts.length"
              @click="localSetNewOrder()"
              class="m-auto submit pr-4"
            >
              {{ t("cart").finishOrder }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import EkInputTextarea from "@/EK-core/components/EK-forms/EK-input-textarea";
import EkTable from "@/EK-core/components/EK-table";
import { mapActions, mapGetters, mapState } from "vuex";
import { getCookie } from "@Ekcore/util/global";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { nullGuid } from "@/EK-core/util/global";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    EkTable,
    EkInputTextarea,
  },
  computed: {
    ...mapState({
      cartCount: (state) => state.webCart.cartCount,
      cartProducts: (state) => state.webCart.cartProducts,
      setOrderDto: (state) => state.webCart.setOrderDto,
      currType: (state) => state.global.currType,
    }),
    ...mapGetters(["totalPrice"]),
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  metaInfo() {
    return {
      titleTemplate: "Awija - " + this.$t("website.cart"),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: "description",
          content: "description",
        },
        {
          name: "keywords",
          content: "keywords, keywords, keywords, ...",
        },
      ],
    };
  },
  data() {
    return {
      cols: [
        {
          label: "اسم المنتج",
          field: "name",
          sortable: false,
        },
        {
          label: "سعر المنتج",
          field: "price",
          sortable: false,
        },
        {
          label: "الكمية",
          field: "quantity",
          sortable: false,
        },
        {
          label: "اجمالي السعر",
          field: "totalPrice",
          sortable: false,
        },

        {
          label: "إزالة",
          sortable: false,
          field: "delete",
        },
      ],
    };
  },
  created() {
    this.getCartItems();
  },
  mounted() {
    window.addEventListener("keypress", this.enterSubmit);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.enterSubmit);
  },
  methods: {
    getProductPrice(data) {
      if (!this.currType)
        return (
          data.price +
          data.attribute.reduce(
            ((prev, next) => {
              return prev.price + next.price;
            },
            0)
          )
        );
      else {
        return (
          data.dollarPrice +
          data.attribute.reduce((prev, next) => {
            return prev + next.dollarPrice;
          }, 0)
        );
      }
    },
    ...mapActions(["getCartItems", "removeCartItem", "updateCart", "setNewOrder"]),
    setNewQuantity(row, qu, n) {
      if (
        qu >= 0 &&
        this.cartProducts.reduce((pre, curr) => {
          if (row.productId == curr.productId) return pre + curr.quantity;
          else return pre;
        }, 0) +
          n >=
          row.limit
      ) {
        this.updateCart({
          hash: getCookie("awijaCart"),
          cartDto: {
            id: row.id,
            name: row.name,
            image: row.image,
            limit: row.limit,
            quantity: qu,
            productId: row.productId,
            price: row.price,
            productMeasurementId: row.productMeasurementId,
            attribute: row.attribute,
          },
        });
        this.cartProducts[row.originalIndex].quantity = qu;
      } else if (qu < 0) {
        row.quantity++;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "لايمكن للكمية ان تكون سالبة",
            icon: "CoffeeIcon",
            variant: "info",
          },
        });
      } else {
        row.quantity++;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `اقل كمية ممكنة هي ` + row.limit + " قطعة",
            icon: "CoffeeIcon",
            variant: "info",
          },
        });
      }
    },

    enterSubmit({ key, code }) {
      if (key == "Enter" || code == "Enter") {
        this.localSetNewOrder();
      }
    },
    localSetNewOrder() {
      this.setNewOrder({
        id: nullGuid,
        language: !this.currType ? 1 : 0,
        orderDate: new Date(),
        note: this.setOrderDto.note,
        totalPrice: this.totalPrice,
        number: 0,
        orderDetails: this.cartProducts
          .map((cp) => {
            let cpPrice = !this.currType ? cp.price : cp.dollarPrice;

            return {
              id: cp.id,
              name: cp.name,
              image: cp.image,
              quantity: cp.quantity,
              price: cp.attribute.reduce((pre, at) => {
                let curPrice = !this.currType ? at.price : at.dollarPrice;
                return pre + curPrice;
              }, cpPrice),
              productMeasurementId: cp.productMeasurementId,
              orderId: nullGuid,
              ProductId: nullGuid,
              productAttributeIds: cp.attribute.map((a) => a.id),
            };
          })
          .filter((pr) => pr.quantity),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-table::v-deep {
  .page-item {
    border-radius: 50% !important;
    margin: 0 5px;
    padding: 3px;
    height: 35px !important;
    width: 35px !important;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(165, 145, 145);
    background: linear-gradient(
      -180deg,
      rgba(238, 238, 238, 1) 0%,
      rgba(71, 73, 74, 1) 100%
    );
    .page-link {
      background-color: #47494a !important;
      color: #fff;
      border-radius: 5rem;
      border: 0;
      max-height: 35px !important;
      max-width: 35px !important;
    }
  }
  .page-item.active {
    height: 42px !important;
    width: 42px !important;
    padding: 2px;
    background: rgb(26, 201, 251);
    background: linear-gradient(
      180deg,
      rgba(26, 201, 251, 1) 0%,
      rgb(106, 109, 110) 100%
    );
    position: relative;
    top: -5px;

    .page-link {
      top: 0 !important;
      color: #1ac9fb !important;
      max-height: 39px !important;
      max-width: 39px !important;
    }
  }
  .prev-item,
  .next-item {
    display: none !important;
  }
  * {
    border: #ccc !important;
  }
  tr {
    border: #777 1px solid !important;
  }
  td,
  h4 {
    color: #fff !important;
  }
  td {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .vgt-table,
  .card {
    border: #ccc 1px solid !important;
    background: radial-gradient(
      104.33% 375.58% at 97.5% 94.44%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
  }
  .card-header {
    padding: 0;
    background: radial-gradient(
      104.33% 375.58% at 97.5% 94.44%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
  }
  .card-footer {
    padding: 5px 35px !important;
    background: radial-gradient(
      104.33% 375.58% at 97.5% 94.44%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
  }
  .table-header {
    padding: 30px 35px;
    width: 100%;
    border-bottom: 1px #ccc solid !important ;
    border-radius: 10px 10px 0px 0px;
  }
  thead {
    th {
      font-weight: normal;
      color: #fff;
      border-bottom: #777 solid 1px !important;
      border: 0;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}
.quantity {
  height: 30px;
  width: 30px;
  padding: 0;
  background: #1ac9fb;
  box-shadow: inset -2px 2px 5px rgba(255, 255, 255, 0.4),
    inset 2px -2px 6px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.custom-bg {
  background-color: var(--custom-dark) !important;
  background: radial-gradient(
    104.33% 375.58% at 97.5% 94.44%,
    rgba(196, 196, 196, 0.07) 0%,
    rgba(196, 196, 196, 0) 100%
  );
  box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px -2px 4px rgba(0, 0, 0, 0.1);
}
.metal-border {
  background: rgb(204, 204, 204);
  background: linear-gradient(
    342deg,
    rgba(204, 204, 204, 1) 0%,
    rgba(238, 238, 238, 1) 25%,
    rgba(119, 119, 119, 1) 50%,
    rgba(238, 238, 238, 1) 75%,
    rgba(119, 119, 119, 1) 100%
  );
  padding: 1px !important;
}
.submit {
  background: #196da7 !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
  border: 0;
  justify-content: center;
  transform: perspective(54px) rotateY(355deg) skewX(360deg);
  width: 160px;
  height: 46px;
}
</style>
