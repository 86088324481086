var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cart"},[_c('b-container',{staticClass:"py-5"},[_c('b-row',[(!_vm.cartProducts.length)?_c('b-col',{staticClass:"text-center mb-3",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"px-5 py-1",attrs:{"variant":"primary","to":"/products"}},[_c('img',{staticClass:"mr-1",attrs:{"src":"/media/svg/shopping-cart.svg","alt":"shopping cart","width":"20"}}),_vm._v(" "+_vm._s(_vm.t("cart").shopNow)+" ")])],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('ek-table',{staticClass:"cart-table rounded",attrs:{"items":_vm.cartProducts,"columns":_vm.cols,"no_delete":"","no_select":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header d-flex justify-content-between w-100"},[_c('h5',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.t("cart").cart))]),_c('h5',{staticClass:"text-light"},[_vm._v(" "+_vm._s(_vm.t("cart").cartCount)+" : "+_vm._s(_vm.cartCount)+" ")])])]},proxy:true},{key:"headers",fn:function(ref){
var props = ref.props;
return [_c('span',[_vm._v(" "+_vm._s(_vm.t("cart")[props.column.field])+" ")])]}},{key:"items.name",fn:function(ref){
var props = ref.props;
return [_c('b-link',{attrs:{"to":'/products/' + props.row.productId}},[_c('div',{staticClass:"d-flex h-100 align-items-center justify-content-center"},[_c('img',{staticClass:"rounded-circle",staticStyle:{"object-fit":"cover"},attrs:{"width":"60","height":"60","src":_vm.$store.getters['app/domainHost'] + '/' + props.row.image}}),_c('div',{staticClass:"ml-1 d-flex flex-column justify-content-center align-items-start"},[_c('h4',[_vm._v(_vm._s(props.row.name))]),_c('div',{staticClass:"text-nowrap text-white"},[(props.row.attribute.length)?_vm._l((props.row.attribute),function(attribute,index){return _c('small',{key:index,staticClass:"text-light"},[_vm._v(" "+_vm._s(attribute.name)+" "+_vm._s(index != props.row.attribute.length - 1 ? "-" : "")+" ")])}):_c('small',[_vm._v(" "+_vm._s(_vm.t("cart").noAddons)+" ")])],2)])])])]}},{key:"items.price",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.getProductPrice(props.row))+" "+_vm._s(!_vm.currType ? _vm.t("sp") : _vm.t("usd"))+" ")]}},{key:"items.quantity",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-button',{staticClass:"quantity",attrs:{"variant":"custom-blue-sky"},on:{"click":function($event){return _vm.setNewQuantity(props.row, ++props.row.quantity, 1)}}},[_c('unicon',{attrs:{"name":"plus","height":"15","fill":"#fff"}})],1),_c('h4',{staticClass:"mx-2 m-0"},[_vm._v(" "+_vm._s(props.row.quantity)+" ")]),_c('b-button',{staticClass:"quantity",attrs:{"variant":"custom-blue-sky"},on:{"click":function($event){return _vm.setNewQuantity(props.row, --props.row.quantity, -1)}}},[_c('unicon',{attrs:{"name":"minus","height":"15","fill":"#fff"}})],1)],1)]}},{key:"items.totalPrice",fn:function(ref){
var props = ref.props;
return [(!_vm.currType)?_c('span',[_vm._v(" "+_vm._s(_vm._f("numFormat")(props.row.attribute.reduce(function (pre, curr) { return props.row.quantity * curr.price + pre; }, props.row.price * props.row.quantity)))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("numFormat")(props.row.attribute.reduce(function (pre, curr) { return props.row.quantity * curr.dollarPrice + pre; }, props.row.dollarPrice * props.row.quantity)))+" ")]),_vm._v(" "+_vm._s(!_vm.currType ? _vm.t("sp") : _vm.t("usd"))+" ")]}},{key:"items.delete",fn:function(ref){
var props = ref.props;
return [_c('b-button',{attrs:{"variant":"flat-secondary btn-icon"},on:{"click":function($event){return _vm.removeCartItem(props.row.id)}}},[_c('unicon',{attrs:{"fill":"#fff","name":"times"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"metal-border rounded p-25"},[_c('div',{staticClass:"custom-bg p-2 rounded d-flex justify-content-between"},[_c('h4',{staticClass:"text-light m-0"},[_vm._v(" "+_vm._s(_vm.t("cart").cartTotalPrice)+" ")]),_c('h4',{staticClass:"text-light m-0"},[_vm._v(" "+_vm._s(_vm._f("numFormat")(_vm.totalPrice))+" "+_vm._s(!_vm.currType ? _vm.t("sp") : _vm.t("usd"))+" ")])])])]),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"metal-border rounded p-25"},[_c('div',{staticClass:"custom-bg p-25 rounded"},[_c('ek-input-textarea',{attrs:{"fieldClass":"bg-transparent text-light border-0","placeholder":_vm.t('cart').note,"name":"note","row":"3"},model:{value:(_vm.setOrderDto.note),callback:function ($$v) {_vm.$set(_vm.setOrderDto, "note", $$v)},expression:"setOrderDto.note"}})],1)])]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"w-100 mt-5 d-flex align-items-center justify-content-center"},[_c('b-button',{staticClass:"m-auto submit pr-4",attrs:{"disabled":!_vm.cartProducts.length},on:{"click":function($event){return _vm.localSetNewOrder()}}},[_vm._v(" "+_vm._s(_vm.t("cart").finishOrder)+" ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }